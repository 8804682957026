<template>
  <b-row>
    <b-col cols="12" class="text-center success">
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
      </div>
      <h2 class="primary-color bold m-b-30">
        You Registerd Successfully
      </h2>
      <a href="#" class="primary-color f-s-15">
        Go to your dashboard
        <font-awesome-icon
          :icon="['fas', 'angle-double-right']"
        ></font-awesome-icon>
      </a>
    </b-col>
  </b-row>
</template>

<script>
export default {}
</script>

<style></style>
