export default {
  "locales": {
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])}
  },
  "nav": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olegancy"])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
    "lawFirms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law firms"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "legalForums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal forums"])},
    "bookLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book library"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}