<template>
  <breadcrumb />
  <router-view />
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  components: { Breadcrumb },
  name: 'bookLibrary',
  data() {
    return {
      currentPage: 1,
      rows: 5,
      perPage: 5,
    }
  },
}
</script>

<style lang="scss"></style>
