<template>
  <breadcrumb />
  <section class="forums container p-t-50 p-b-50">
    <div class="row">
      <div class="col-lg-9">
        <div class="forum m-b-30 m-r-20">
          <div class="row forum-title text-white p-10 m-b-20">
            <div class="col-md-4">
              <a href="cat-forum.php">
                <h4 class="text-white m-b-0">
                  <i class="fas fa-angle-double-right m-r-5"></i>
                  Main Forum
                </h4>
              </a>
            </div>
            <div class="col-md-2 d-md-block d-none d-sm-none text-center">
              Topics
            </div>
            <div class="col-md-2 d-md-block d-none d-sm-none text-center">
              Posts
            </div>
            <div class="col-md-4 d-none d-md-block d-sm-none">
              Last post
            </div>
          </div>

          <!-- if Sub Forum have post -->
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <!-- END -->

          <!-- if Sub Forum have post -->
          <div class="row sub-forum">
            <div class="col-md-5">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum English description for sub
                forum
              </div>
            </div>
            <div
              class="col-md-7 d-flex justify-content-center align-items-center d-md-block d-sm-none d-none"
            >
              <span class="text-muted f-s-14">No posts to show</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-5">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum English description for sub
                forum
              </div>
            </div>
            <div
              class="col-md-7 d-flex justify-content-center align-items-center d-md-block d-sm-none d-none"
            >
              <span class="text-muted f-s-14">No posts to show</span>
            </div>
          </div>
          <!-- END -->
        </div>

        <div class="forum m-b-30 m-r-20">
          <div class="row forum-title text-white p-10 m-b-20">
            <div class="col-md-4">
              <a href="cat-forum.php">
                <h4 class="text-white m-b-0">
                  <i class="fas fa-angle-double-right m-r-5"></i>
                  Main Forum
                </h4>
              </a>
            </div>
            <div class="col-md-2 d-md-block d-none d-sm-none text-center">
              Topics
            </div>
            <div class="col-md-2 d-md-block d-none d-sm-none text-center">
              Posts
            </div>
            <div class="col-md-4 d-none d-md-block d-sm-none">
              Last post
            </div>
          </div>

          <!-- if Sub Forum have post -->
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-4 item">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum
              </div>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>651</h5>
            </div>
            <div
              class="col-md-2 item d-md-flex justify-content-center align-items-center d-md-block d-none d-sm-none"
            >
              <h5>500</h5>
            </div>
            <div class="col-md-4 item">
              <a href="forum-article.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-book-open m-r-5"></i>
                  this is test topic
                </h5>
              </a>
              <span class="text-muted f-s-13">By Author Name , 9 Oct 2018</span>
            </div>
          </div>
          <!-- END -->

          <!-- if Sub Forum have post -->
          <div class="row sub-forum">
            <div class="col-md-5">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum English description for sub
                forum
              </div>
            </div>
            <div
              class="col-md-7 d-flex justify-content-center align-items-center d-md-block d-sm-none d-none"
            >
              <span class="text-muted f-s-14">No posts to show</span>
            </div>
          </div>
          <div class="row sub-forum">
            <div class="col-md-5">
              <a href="sub-forum.php" class="primary-color-hover">
                <h5>
                  <i class="fas fa-stream m-r-5"></i>
                  Sub Forum
                </h5>
              </a>
              <div class="desc text-muted f-s-14">
                English description for sub forum English description for sub
                forum
              </div>
            </div>
            <div
              class="col-md-7 d-flex justify-content-center align-items-center d-md-block d-sm-none d-none"
            >
              <span class="text-muted f-s-14">No posts to show</span>
            </div>
          </div>
          <!-- END -->
        </div>
      </div>
      <side-topic />
    </div>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import SideTopic from '@/components/SideTopic.vue'
export default {
  name: 'legalForums',
  components: { Breadcrumb, SideTopic },
}
</script>

<style lang="scss">
/************** Legal-Forum **************/
.forums .forum-title {
  background-color: #999;
}
.forums .sub-forum {
  margin-bottom: 5px;
  padding-bottom: 15px;
  padding-top: 20px;
}
.forums .sub-forum:nth-child(odd) {
  background-color: #f8f9fa;
}
.forums .sub-forum svg {
  color: #303f9f;
}
/************** Legal-Forum **************/
</style>
