<template>
  <breadcrumb />
  <section class="pricing container p-t-50 p-b-50">
    <div class="title text-center">
      <h2 class="text-center f-s-35">Business Pricing</h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley.
      </p>
    </div>

    <div class="row justify-content-center business m-b-50">
      <div class="col-md-4 col-sm-9 col-11 box text-center p-30 m-b-30">
        <h4 class="title">Small Business</h4>
        <h3 class="m-b-15">
          No Montly Subscription
        </h3>
        <span class="d-block">100/Y</span>
        <ul class="details m-t-20 m-b-0">
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
        </ul>
        <a
          href="#"
          data-toggle="modal"
          data-target="#loginModal"
          class="btn m-t-20"
        >
          Subscribe
        </a>
      </div>
      <div class="col-md-4 col-sm-9 col-11 box text-center p-30 m-b-30">
        <h4 class="title">Mid Business</h4>
        <h3 class="m-b-15">
          No Montly Subscription
        </h3>
        <span class="d-block">300/Y</span>
        <ul class="details m-t-20 m-b-0">
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
        </ul>
        <a
          href="#"
          data-toggle="modal"
          data-target="#loginModal"
          class="btn m-t-20"
        >
          Subscribe
        </a>
      </div>
      <div class="col-md-4 col-sm-9 col-11 box text-center p-30 m-b-30">
        <h4 class="title">Large Business</h4>
        <h3 class="m-b-15">
          No Montly Subscription
        </h3>
        <span class="d-block">500/Y</span>
        <ul class="details m-t-20 m-b-0">
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
          <li>
            Feature name
          </li>
        </ul>
        <a
          href="#"
          data-toggle="modal"
          data-target="#loginModal"
          class="btn m-t-20"
        >
          Subscribe
        </a>
      </div>
    </div>

    <div class="title text-center">
      <h2 class="text-center f-s-35">Individuals Pricing</h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley.
      </p>
    </div>

    <div class="row justify-content-center individual m-b-30">
      <div class="col-lg-3 col-sm-6 col-11 m-b-30">
        <div class="box text-center">
          <h5 class="price-title bg-primary-color text-white p-10 m-b-0">
            Individuals Mini
          </h5>
          <div class="content bg-light">
            <div class="price">
              <sup>$</sup>
              3
              <sub>/mo</sub>
            </div>
            <span class="d-block">30/Y</span>
            <ul class="details bg-white m-t-20 m-b-0">
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
            </ul>
            <div class="bg-white">
              <a
                href="#"
                data-toggle="modal"
                data-target="#loginModal"
                class="btn m-b-20"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-11 m-b-30">
        <div class="box text-center">
          <h5 class="price-title bg-primary-color text-white p-10 m-b-0">
            Individuals Mini
          </h5>
          <div class="content bg-light">
            <div class="price">
              <sup>$</sup>
              3
              <sub>/mo</sub>
            </div>
            <span class="d-block">30/Y</span>
            <ul class="details bg-white m-t-20 m-b-0">
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
            </ul>
            <div class="bg-white">
              <a
                href="#"
                data-toggle="modal"
                data-target="#loginModal"
                class="btn m-b-20"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-11 m-b-30">
        <div class="box text-center">
          <h5 class="price-title bg-primary-color text-white p-10 m-b-0">
            Individuals Mini
          </h5>
          <div class="content bg-light">
            <div class="price">
              <sup>$</sup>
              3
              <sub>/mo</sub>
            </div>
            <span class="d-block">30/Y</span>
            <ul class="details bg-white m-t-20 m-b-0">
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
            </ul>
            <div class="bg-white">
              <a
                href="#"
                data-toggle="modal"
                data-target="#loginModal"
                class="btn m-b-20"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-11 m-b-30">
        <div class="box text-center">
          <h5 class="price-title bg-primary-color text-white p-10 m-b-0">
            Individuals Mini
          </h5>
          <div class="content bg-light">
            <div class="price">
              <sup>$</sup>
              3
              <sub>/mo</sub>
            </div>
            <span class="d-block">30/Y</span>
            <ul class="details bg-white m-t-20 m-b-0">
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
              <li>
                Feature name
              </li>
            </ul>
            <div class="bg-white">
              <a
                href="#"
                data-toggle="modal"
                data-target="#loginModal"
                class="btn m-b-20"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  components: { Breadcrumb },
  name: 'Pricing',
}
</script>

<style scoped lang="scss">
/*************** Pricing ****************/
.pricing .business .box {
  border: solid 1px #303f9f;
  transition: all 0.4s ease-in-out;
  transform: scale(1.05);
}
.pricing .business .box .title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #303f9f;
  margin: 15px auto 0;
}
.pricing .business .box:nth-child(2) {
  background-color: #303f9f;
  color: white;
  transform: scale(1);
}
.pricing .business .box:nth-child(2) .btn {
  background-color: white;
  color: #303f9f;
}
.pricing .business .box:nth-child(2) .title::after {
  background-color: white;
}
.pricing .individual .price {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #303f9f;
  margin: 20px auto;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
}
.pricing .individual .box .content {
  border: solid 2px #f8f9fa;
  transition: all 0.4s ease-in-out;
}
.pricing .individual .box:hover .content {
  border: solid 2px #303f9f;
}
.pricing .box ul.details {
  padding: 10px 0;
}
.pricing .box ul.details li {
  margin-bottom: 10px;
  display: block;
  position: relative;
}
.pricing .box ul.details li::before {
  content: '\f00c';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  color: #303f9f;
}
.pricing .business .box:nth-child(2) ul.details li::before {
  color: white;
}
/* .pricing .individual .box:nth-child(2) , .pricing .individual .box:nth-child(4){
		background-color: #303f9f;
		color: white;
	}
	.pricing .individual .box:nth-child(2) .price , .pricing .individual .box:nth-child(4) .price{
		border: solid 1px white;
	}
	.pricing .individual .box:nth-child(2) .btn , .pricing .individual .box:nth-child(4) .btn{
		background-color: white;
	} */

/*************** Pricing ****************/
</style>
