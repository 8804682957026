<template>
  <footer>
    <div
      class="copyright bg-secondary-color p-t-20 p-b-15 text-center text-white"
    >
      <img src="@/assets/images/logo.png" class="logo m-b-15" alt="logo" />
      <p class="m-b-5">All CopyRights Reserved To Olegancy.com 2017</p>
      <p class="m-b-5">
        Powered By
        <a
          href="http://www.technomasr.com"
          class="primary-color primary-color-hover"
        >
          TechnoMasr
        </a>
        Co
      </p>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
footer {
  .copyright {
    padding-top: 20px;
  }
}
</style>
