<template>
  <div class="item">
    <a href="#">
      <img src="@/assets/images/logo4.png" alt="Owl Image1" />
    </a>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.item {
  margin: 20px 10px 10px;
  text-align: center;
}
</style>
