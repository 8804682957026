<template>
  <section class="single-book container p-t-50 p-b-50">
    <div class="row">
      <div class="col-lg-8 col-md-7">
        <article>
          <h3 class="primary-color bold m-b-20">Book Title</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </article>
        <div class="links">
          <a href="#" class="btn m-b-10 download" download>
            <i class="fas fa-download m-r-5"></i>
            Download Book
          </a>
          <a
            href="http://www.wufai.edu.tw/%E7%B6%B2%E9%A0%81%E6%8A%80%E8%A1%93%E4%B8%AD%E5%BF%83/datasheet/HTML%20and%20CSS%20design%20and%20build%20websites.pdf"
            target="_blank"
            class="btn m-b-10 read"
          >
            <i class="fas fa-book-open m-r-5"></i>
            Read Book
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-5">
        <figure>
          <img src="SiteAssets/images/book1.jpg" alt="book title" />
        </figure>
      </div>
    </div>

    <div class="related archive-books m-t-50">
      <h3 class="primary-color m-b-25 bold">Related Books</h3>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-sm-6 col-11 m-b-30">
          <figure>
            <img src="SiteAssets/images/book1.jpg" alt="book title" />
            <div class="overlay">
              <div class="text text-white">
                <h4 class="bold">Book Name</h4>
                <ul class="p-0 text-white m-t-30 f-s-17">
                  <li>
                    Author: author name
                  </li>
                  <li>
                    Views: 5
                  </li>
                </ul>
                <a href="single-book.php">
                  <i class="fas fa-book-open"></i>
                </a>
              </div>
            </div>
          </figure>
        </div>
        <div class="col-lg-3 col-sm-6 col-11 m-b-30">
          <figure>
            <img src="SiteAssets/images/book2.jpg" alt="book title" />
            <div class="overlay">
              <div class="text text-white">
                <h4 class="bold">Book Name</h4>
                <ul class="p-0 text-white m-t-30 f-s-17">
                  <li>
                    Author:Author name
                  </li>
                  <li>
                    Views: 5
                  </li>
                </ul>
                <a href="single-book.php">
                  <i class="fas fa-book-open"></i>
                </a>
              </div>
            </div>
          </figure>
        </div>
        <div class="col-lg-3 col-sm-6 col-11 m-b-30">
          <figure>
            <img src="SiteAssets/images/book3.jpg" alt="book title" />
            <div class="overlay">
              <div class="text text-white">
                <h4 class="bold">Book Name</h4>
                <ul class="p-0 text-white m-t-30 f-s-17">
                  <li>
                    Author:Author name
                  </li>
                  <li>
                    Views: 5
                  </li>
                </ul>
                <a href="single-book.php">
                  <i class="fas fa-book-open"></i>
                </a>
              </div>
            </div>
          </figure>
        </div>
        <div class="col-lg-3 col-sm-6 col-11 m-b-30">
          <figure>
            <img src="SiteAssets/images/book1.jpg" alt="book title" />
            <div class="overlay">
              <div class="text text-white">
                <h4 class="bold">Book Name</h4>
                <ul class="p-0 text-white m-t-30 f-s-17">
                  <li>
                    Author:Author name
                  </li>
                  <li>
                    Views: 5
                  </li>
                </ul>
                <a href="single-book.php">
                  <i class="fas fa-book-open"></i>
                </a>
              </div>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style></style>
